import React from 'react';
import Layout from '../components/layout';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'gatsby';

export default () => {
  return (
    <Layout title={`Page Not Found`}>
      <Container>
        <Row className='mt-5'>
          <Col className='mt-5'>
            <h1>About Us</h1>
            <div className='mt-5'>
              <p>We are a small team of developers aimed to provide quality applications to users just like you.</p>
            </div>
            <Link to='/'>Back to home</Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};
